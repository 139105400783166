// Here you can add other styles

.btn {
	cursor: pointer;
}

.table th, .table td {
	vertical-align: middle;
}

.card-header-title {
	font-size: 1.3rem;
	line-height: 35px;
	margin: 0;
}

.photo {
	background-color: #f2f2f2;
	background-position: center center;
	background-size: cover;
	border: 1px solid #e2e2e2;
	border-radius: 9999px;
	display: block;
	height: 60px;
	width: 60px;
}

.ql-editor p {
	padding: 10px 0;
}

.rdsu-image img {
	max-height: 100%;
	max-width: 100%;
}

.media {
	display: block;
	height: 400px;
	margin: 0 -5px;
	overflow: auto;
}

.media__trigger {
	margin: 10px 0 0 0;
}

.media__item {
	background: #f2f2f2 center center no-repeat;
	background-size: contain;
	border: 1px solid #f2f2f2;
	cursor: pointer;
	display: inline-block;
	height: 130px;
	margin: 5px;
	width: 130px;
}

.media__preview {
	background: #f2f2f2 center center no-repeat;
	background-size: contain;
	height: 100px;
	left: 20px;
	position: absolute;
	width: 100px;
	top: 5px;
}

.media__item--selected {
	border-color: #20a8d8;
}

body .react-bs-container-body {
	overflow: visible;
}